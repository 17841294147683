.gpt3__blog-container_article {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  background: var(--color-footer);
}

.gpt3__blog-container_article-image {
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.gpt3__blog-container_article-image img {
  width: 100%;
  height: 100%;
    filter: brightness(0.5) grayscale(80);
    transition: 0.5s ease-in-out;
    
    
    
}
.gpt3__blog-container_article-image img:hover {
  filter: brightness(1) grayscale(0);

}

.gpt3__blog-container_article-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1rem 1.5rem;
  height: 100%;
}

.gpt3__blog-container_article-content p {
  font-family: var(--font-family);
  font-size: 11.65px;
  font-weight: 700;
  line-height: 30px;

  color: #fff;
}

.gpt3__blog-container_article-content h3 {
  font-family: var(--font-family);
  font-size: 25.11px;
  font-weight: 800;
  line-height: 30.30px;
  
  color: #fff;
  margin-bottom: 2rem;
  cursor: pointer;
}
.gpt3__blog-container_article-content button{
  background: var(--gradient-bar);
  
  border-radius: 2rem;
}
.gpt3__blog-container_article-content p:last-child {
  cursor: pointer;
}


@media screen and (max-width: 550px) {
  .gpt3__blog-container_article-content h3 {
      font-size: 18px;
      line-height: 25p;
  }
}