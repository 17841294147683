.gpt3__features {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.gpt3__features-heading {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  text-align: left;
  margin-right: 5rem;
  cursor: pointer;
}

.gpt3__features-heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
}

.gpt3__features-heading p {
  color: var(--color-subtext);
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-family);

  margin-top: 2rem;
}

.gpt3__features-container {
  /* flex: 1.5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column; */
  flex: 1.5;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: flex-start;
  


}

@media screen and (max-width: 990px) {
  .gpt3__features {
      flex-direction: column;
  }

  .gpt3__features-heading {
      margin: 0 0 2rem 0;
  }
}


@media screen and (max-width: 550px) {
  .gpt3__features-heading h1 {
      font-size: 28px;
      line-height: 38px;
  }
}


.arrow{
  position:relative;
  width:30px;
  height: 30px;
  cursor:pointer;
/*   border:solid 1px white; */
  transition:0.5s;
  overflow:hidden;
}

.arrow:hover{
/*  animation:borderAni 5s cubic-bezier(0, 0.6, 1, 0.4); */
      border:solid 2px white; 
    border-radius:50%;
}
.arrow:after{
  position:absolute;
  display:block;
  content:"";
  color:white;
  width: 20px;
  height: 15px;
/*   border:solid 1px;  */
  top:-1px;
  border-bottom:solid 2px;
  transform:translatex(4px);
}

.arrow:before{
  position:absolute;
  display:block;
  content:"";
  color:white;
  width: 80px;
  height: 80px;
/*   border:solid 1px;  */
  border-top:solid 2px;
  border-left:solid 2px;
  top:50%;
  left:2px;
  transform-origin:0% 0%;
  transform:rotatez(-45deg);

}
.arrow:hover:before{
 animation: aniArrow01 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}

.arrow:hover:after{
 animation: aniArrow02 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}


@keyframes aniArrow01 {
  0% {
    transform:rotatez(-45deg) translateY(30px) translateX(30px);
  }
  100% {
    transform: rotatez(-45deg) translateY(-35px) translateX(-35px);
  }
}


@keyframes aniArrow02 {
  0% {
    transform:translateX(45px);
  }
  100% {
    transform:translateX(-44px);
  }
}

@keyframes borderAni{
   0% {
    border:solid 2px white;
  }
  100% {    
    border:solid 2px white; 
    border-radius:50%;
  }
}